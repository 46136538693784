@import "fonts";
@import "mixins";

:root {
    --input-padding-x: 0.75rem;
    --input-padding-y: 0.75rem;
}

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 0.875rem;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

body {
    .blue {
        background-color: blue;
    }

    font-family: "Roboto",
    "Open Sans",
    sans-serif;

    #wp {
        width: 100px;
        background-color: white;
        border-right: solid 1px #b9b9b9;

        #separator {
            background-color: #2666cf;
            color: white;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0.1em;
        }

        #list-wp {
            padding-top: 10px;
            overflow-y: auto;

            .app-menu {
                margin: 5px 5px;
                justify-content: center;
                align-items: center;
                background-color: white;
                border: solid 1px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                min-width: 75px;
                min-height: 75px;
                max-width: 75px;
                max-height: 75px;

                span {
                    text-align: center;
                    white-space: nowrap;
                    max-width: 70px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 11px;
                    padding-left: 5px;
                    padding-right: 5px;
                }

                &:hover {
                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
                    border: solid 1px #ff6b6c;
                }
            }
        }
    }

    #form-login {

        input[type="text"],
        input[type="password"],
        textarea,
        select {
            outline: none;
            appearance: none;
        }

        #show-pasword {
            cursor: pointer;
        }

        .inpg {
            background-color: white;
            box-sizing: border-box;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
            border-radius: 45px;
            border: solid 1px rgba(0, 0, 0, 0.25);
            height: 35px;

            input[type="text"],
            input[type="password"],
            select {
                padding-left: 15px;
                background-color: transparent;
                border: none;
            }
        }

        .btn {
            box-sizing: border-box;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
            border-radius: 45px;
            border: solid 1px rgba(0, 0, 0, 0.25);
            font-family: "Roboto Condensed";
            letter-spacing: 0.1em;
            font-weight: 800;

            &-primary {
                background-color: #2666cf;
            }

            &:hover {
                background-color: #0069d9;
            }

            &:active {
                box-shadow: unset;
            }
        }

        .strike {
            display: block;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            color: #9d9d9d;

            >span {
                position: relative;
                display: inline-block;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 9999px;
                    height: 1px;
                    background: #9d9d9d;
                }

                &:before {
                    right: 100%;
                    margin-right: 15px;
                }

                &:after {
                    left: 100%;
                    margin-left: 15px;
                }
            }
        }

        .opsi {
            display: block;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            color: #9d9d9d;

            >span {
                position: relative;
                display: inline-block;
            }
        }
    }

    #desktop {
        background: no-repeat url("../img/bg-kiri.png"), no-repeat url("../img/sun.png"),
            no-repeat url("../img/bg-kanan.png");
        background-color: #effffd;
        background-size: 50%, auto, 30%;
        background-position-y: bottom, bottom, bottom;
        background-position-x: left, 70%, right;
        padding: 100px 100px;

        #welcome {
            &-title {
                font-family: "Roboto Condensed";
                font-style: normal;
                font-size: 40px;
                letter-spacing: 0.1em;
                color: #2666cf;
            }

            &-detail {
                font-family: "Roboto";
                color: #2666cf;
                font-size: 20px;
                letter-spacing: 5px;
            }
        }
    }
}